.persons {
  width: 170px;
  float: left;
  background-color: rgb(255, 255, 255);
  padding: 16px;
  font-size: 15px;
  color: dimgray;
  font-weight: 400;

  overflow: hidden;
}

.personPic {
  border-radius: 100%;
  width: 130px;
  height: 130px;
  object-fit: cover;
}

.peoplename {
  font-size: 1.1rem;
  font-weight: 400;
  color: black;
  /* make it center */
  text-align: center;
}

.kiosk {
 /* background: #282c34; */
  min-height: 100vh;
}

.kiosk__container {
  display: flex;
  color: white;
  /* width: 850px; */
  width: 80%;

  margin: 0 auto;
  padding: 20px;
  /* make corners round */
  border-radius: 10px;
  /* add shadow */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* make background white */
  background: white;

  flex-wrap: wrap;

  /* justify-content: left; */

  justify-content: space-around;
}

.centerBusinessLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  padding: 10px;
}

input {
  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  /* background: #282c34; */
  color: white;
  font-size: 20px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  /* center inside div */
  
  display: block;

  margin-left: auto;
  margin-right: auto;

}

.kioskSearch {
  width: 50%;
  min-width: 85%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;

  /* MAKE IT CENTERED */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

label {
  color: rgb(255, 238, 238);
  font-size: 20px;
  /* make label center */
}

.kiosk__card {
  /* show 5 cards per row inside container  */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.kiosk__card__image {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.kiosk__card:hover {
  background-color: #f1f1f1;
}

.kiosk__card__title {
  color: black;
  font-size: 20px;
  font-weight: 500;
}

.kiosk__card__title:hover {
  color: #4caf50;
}

.kiosk__card__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.kiosk__card__description {
  margin-top: 10px;
}

.kiosk__card__description__text {
  margin-top: 10px;
}

.kiosk__card__description__text:hover {
  color: #4caf50;
}

.kiosk__card__description__text__link {
  color: #4caf50;
  text-decoration: none;
}

.kiosk__card__description__text__link:hover {
  color: #4caf50;
  text-decoration: underline;
}
