@tailwind base;
@tailwind components;
@tailwind utilities;

.buttonClose {
  border-radius: 8px;
  background-color: rgb(82, 82, 247);
  color: white;
  padding: 15px 32px;

  font-size: 16px;
  border: 2px solid #ffffff;
}

input {
 
  border: 2px solid #ffffff;
}

.buttonSend {
  border-radius: 8px;
  background-color: rgb(51, 162, 58);
  color: white;
  padding: 15px 32px;

  font-size: 16px;
  border: 2px solid #ffffff;

  float: right;
}

.NameHeader {
  font-size: 20px;
  color: rgb(81, 81, 81);
  grid-area: Text;
}

.textmelding {
  resize: none;
  float: right;

  width: 100%;

  height: 100px;

  border-radius: 15px;
  border-color: rgb(218, 218, 218);
  padding: 2%;
  font-size: 1.75rem /* 60px */;
  line-height: 1;
  color: dimgray;

  grid-area: TextInput;
}

.ModelForm {
  position: fixed;
  top: 50%;
  left: 50%;
  float: right;
  color: black;
  padding: 16px;
  background-color: white;
  width: 800px;
  transform: translate(-50%, -50%);
  border-radius: 0.375rem;
  border-radius: 15px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  gap: 15px 0px;
  grid-template-areas:
    "picture Text"
    "TextInput TextInput"
    "Keyb Keyb"
    "Input Input";
}

.sendHeader {
  background-color: rgb(255, 255, 255);
}

.sendLogo {
  border-radius: 100%;
  width: 200px;
  height: 200px;
  grid-area: picture;
}

.Keyb {
  grid-area: Keyb;
}

.buttons {
  grid-area: Input;
}

@media screen and (max-width: 800px) {
  .Keyb {
    display: none;
  }

  .ModelForm {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    top: 50%;
    left: 50%;
    color: black;
    padding: 16px;
    background-color: white;
    width: 350px;
    transform: translate(-50%, -50%);
    border-radius: 0.375rem;
    border-radius: 15px;
  }



  .sendLogo {
    border-radius: 100%;
    width: 140px;
    height: 140px;
    position: relative;
    display: inline-block;
  }

  .textmelding {
    resize: none;
    height: 200px;
    width: 100%;
    border-radius: 15px;
    border-color: rgb(218, 218, 218);
    padding: 2%;
    font-size: 1.75rem /* 60px */;
    line-height: 1;
    color: dimgray;
  }

.personName {
  font-size: 20px;
}

  .NameHeader {
    font-size: 16px;
    color: rgb(81, 81, 81);
  }
}
